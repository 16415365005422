html {
    height: 100%;
}

:root {
    // initial (fallback) variables
    --header-mode: block;
    --header-height: 7.5em;
    --page-font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    --page-font-size: 0.95rem;
    --page-font-color: #111111;
    --form-field-font-family: Arial, Helvetica, sans-serif;
    --form-field-font-color: #111111;
    --page-background: #919191;
    --content-background: #ffffff;
    --form-field-background: #fbfbfb;                   // f5f5f5
    --form-field-border-color: #aaaaaa;
    --form-field-height: 1.8rem;
    --form-field-border-radius: 3px;
    --form-field-margin: 2em;
    --form-box-shadow: 0 3px 7px 0 #00000055;
    --form-field-padding: 0.3em;

    --button-background-color: #f2f2f2;                 // f5f5f5
    --button-background-image: none;
    --button-border: 1px solid #888888;
    --button-font-color: #111111;
    --button-font-weight: normal;
    --button-padding: 0 5px 0 5px;

    --loader-button-width: 1.3em;

    --time-picker-height: 1.8em;
    --time-picker-padding: 0.1em;

    --tooltip-background-color: #f2f2f2;
    --tooltip-border-color: #888888;
    --tooltip-font-color: #111111;

    --page-padding: 3px;

    @media only screen and (min-width: 480px) {
        --page-padding: 5%;
    }

    @media only screen and (min-width: 768px) {
        --page-padding: 10%;
    }

    // theme 12drive
    --header-mode-12drive: none;
    --header-height-12drive: 0px;
    //--page-font-family-12drive: Roboto;
    --page-font-family-12drive: Arial, Helvetica, sans-serif;
    --page-font-size-12drive: 14px;
    --page-font-color-12drive: #505e78;
    --form-field-font-family-12drive: Arial, Helvetica, sans-serif;
    --form-field-font-color-12drive: #333333;
    --page-background-12drive: #ffffff;
    --content-background-12drive: #ffffff;
    --form-field-background-12drive: #f8f8f9;
    --form-field-border-color-12drive: #dce0e9;
    --form-field-height-12drive: 36px;                  // original: 48
    --form-field-border-radius-12drive: 6px;
    --form-field-margin-12drive: 1em;
    --form-box-shadow-12drive: 0 4px 8px 0 rgba(80, 94, 120, 0.8);
    --form-field-padding-12drive: 6px;

    --button-background-color-12drive: #294057;
    --button-background-image-12drive: none;
    //--button-background-image-12drive: linear-gradient(to bottom, #ffaf00, #ec9e29);
    --button-border-12drive: 1px solid var(--button-background-color-12drive);
    --button-font-color-12drive: #ffffff;
    --button-font-weight-12drive: 500;
    --button-padding-12drive: 0 10px 0 10px;

    --loader-button-width-12drive: 1.0em;

    --time-picker-height-12drive: 28px;
    --time-picker-padding-12drive: 3px;

    --tooltip-background-color-12drive: #294057;
    --tooltip-border-color-12drive: #294057;
    --tooltip-font-color-12drive: #ffffff;
}

body#inb-crw-main {
    background-color: var(--page-background);
    margin: 0;
    //min-height: 100%;
    font-family: var(--page-font-family);
    font-size: var(--page-font-size);
    color: var(--page-font-color);
    height: 100%;
    overflow: auto;
}

.hidden {
    display: none !important; 
}

.inb-crw-focus {

    box-shadow: inset 0px 0px 0px 1px #f60;
}

@keyframes fadeIn {

    0% {opacity: 0;z-index: 1;}
    100% {opacity: 1;z-index: 1;}
}

@keyframes fadeOut {

    0% {opacity: 1;z-index: 1;}
    99% {z-index: 1;}
    100% {opacity: 0;z-index: -5;}
}
  
.animate-fade-in {
    animation: fadeIn 0.5s;
    animation-fill-mode: forwards;
}

.animate-fade-out {
    animation: fadeOut 0.5s;
    animation-fill-mode: forwards;
}

#inb-crw-header {

    height: var(--header-height);
    max-width: 40em;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    text-align: center;
    background-color: var(--content-background);
    margin-left: auto;
    margin-right: auto;
    //display: var(--header-mode);

    .inb-crw-header {
        display: var(--header-mode);
    }

    img {
        width: 19em;
        height: 5em;
    }
}

#inb-crw-footer {

    position: absolute;
    height: 4em;
    width: calc(100% - var(--page-padding) - var(--page-padding));
    max-width: 40em;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    margin-left: auto;
    margin-right: auto;

    background-color: var(--content-background);
}

#inb-crw-content-section {

    //height: calc( 100vh - var(--header-height) - 4em);
    //min-height: 12.5em;
    min-height: calc( 100vh - var(--header-height) - 4em);
}

#inb-crw-content {

    height: 100%;
    min-height: inherit;
}

#inb-crw-content-buffer {

    height: 100%;
    min-height: inherit;
}

.inb-crw-flex-box {
    display: flex;
}

.inb-crw-label {

    line-height: var(--form-field-height);
    max-width: 35em;
    text-align: left;
    font-size: 1em;
    min-height: var(--form-field-height);

    //margin-top: auto;
    //margin-bottom: auto;
    height: var(--form-field-height);
}

.inb-crw-label.multiline {
    line-height: 1.3em;
    min-height: unset;
}

.inb-crw-spacer {
    height: 1px;
}

.inb-crw-heading-1 {

    width: 100%;
    margin-bottom: 1.5em;
    font-size: 1em;
}

.inb-crw-heading-2 {

    width: 100%;
    //margin-bottom: 0.5em;         // use class row to set margin bottom
    font-size: 1em;
    line-height: var(--form-field-height);
}

.inb-crw-heading-2.row {
    line-height: unset;
    margin-top: calc(var(--form-field-margin) * 1.2);
    margin-bottom: 0.5em;
}

.inb-crw-heading-3 {

    margin-bottom: 0.5em;
    font-size: 1.1em;
    text-align: center;
}

.inb-crw-heading-4 {
    line-height: unset;
    width: 100%;
    font-size: 1em;
}

.inb-crw-bottom-space {

    margin-bottom: 1em;
}

.inb-crw-last {

    margin-bottom: var(--form-field-margin) !important;             // override minimum value of margin when set
}

#inb-crw-product-img-view-container {

    margin-top: var(--form-field-margin);

    .center {
        margin: auto;
        display: table;
    }
}

#inb-crw-btn-crop {

    margin-top: calc(var(--form-field-height) + var(--form-field-margin) * 0.5);
}

#inb-crw-product-img-view {

    width: 18em;
    height: 10.125em;
    background-color: #f6f6f6;
    border: 1px solid var(--form-field-border-color);
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    padding: 0;

    img {
        width: inherit;
        height: inherit;
        display: block;
    }
}

#inb-crw-crop {

    margin: auto;
    //width: 24.25em;
    height: 16.375em;
    margin-bottom: 5em;
    margin-top: var(--form-field-margin);
}

.inb-crw-dot {
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    //margin-left: 0.9em;
    //margin-right: 0.9em;
    margin-left: calc((100% - 3em) / 12);
    margin-right: calc((100% - 3em) / 12);
    float: left;
}

.inb-crw-dot.active {
    background-color: #ff6600;
}

.inb-crw-dot.inactive {
    background-color: #bbbbbb;
}

#inb-crw-progressbar {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5em;
    //width: 13.8em;
    width: 44%;
    //height: 0.75em;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

#inb-crw-progress-container {

    height: 3em;
    margin: auto;
    display: flex;
    padding-top: 1em;

    .left {
        //width: 4em;
        width: 28%;
        text-align: right;
    }
    .right {
        //width: 4em;
        width: 28%;
        text-align: left;
    }
}

.inb-crw-form-h-container {

    margin: auto;
    display: table;

    .long {
        min-width: 12.5em;
    }

    .short {
        min-width: 6.25em;
    }
}

.inb-crw-form-h-group {

    .left {
        float: left;
    }

    .right {
        float: right;
    }
}

.inb-crv-v-group {

    display: table;

    input {
        margin-bottom: 0.5em;
    }

    select {
        margin-bottom: 0.5em;
    }
}

.inb-crw-form-h-group.left {
    float: left;
}

.inb-crw-form-h-group.right {
    float: right;
}

.inb-crw-form-group-table {
    margin-left: auto;
    margin-right: auto;

    td {
        padding-bottom: 0.5em;
    }
}

.inb-crw-h-group {

    margin-bottom: 0.5em;

    .left {
        float: left;
    }

    .right {
        float: right;
        text-align: right;
    }

    .center {
        margin-left: auto;
        margin-right: auto;
        display: table;
    }
}

.inb-crw-short {
    width: 3em;
}

.inb-crw-middle {
    width: 7em;
}

.inb-crw-long {
    width: 11em;
}

.inb-crw-very-long {
    width: 27em;
}

.inb-crw-time {
    width: 5.5em;
    padding-right: 2px;
}

.inb-crw-disabled {
    opacity: 0.6;
}

select {
    background-color: var(--form-field-background);
    //background-image: linear-gradient(#f5f5f5, #e0e0e0);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28150, 150, 150%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right -1em center;
    background-repeat: no-repeat;
    background-size: 0.55em 0.35em;
    transition: box-shadow 0.5s,border-color 0.25s ease-in-out,-webkit-box-shadow 0.5s;
    border-style: solid;
    border-width: 1px;
    border-color: var(--form-field-border-color);
    border-radius: var(--form-field-border-radius);
    font-family: var(--form-field-font-family);
    font-size: 1em;
    color: var(--form-field-font-color);
    line-height: 1.6em;
    padding: 0 calc(1.1em + var(--form-field-padding)) 0 var(--form-field-padding);
    margin-left: 0.3em;
    margin-right: 0.3em;
    //margin-bottom: 5px;
    //height: 1.9em;
    height: calc(var(--form-field-height) + 2px);

    appearance: none;
    -webkit-appearance: none;
}

select::-ms-expand {
    display: none;
}

select:disabled {
    opacity: 0.6;
}

#inb-crw-common-checkinout-container {
    display: flow-root;
}

#inb-crw-checkinout-day-container {

    margin-left: 25px;

    .inb-crw-input-element {
        margin-bottom: 4px;
        border: 1px solid white;
    }
}

.inb-crw-timepicker {
    display: flex;
    margin-left: 4px;
    margin-right: 4px;
}

select.inb-crw-select-time {
    appearance: none;
    -webkit-appearance: none;
    padding: 0 var(--time-picker-padding) 0 var(--time-picker-padding);
    height: var(--time-picker-height);
    line-height: 1.3em;
    margin-top: calc( (var(--form-field-height) - var(--time-picker-height)) * 0.5);
}

select.inb-crw-select-time::-ms-expand {
    display: none;                                  // for iE
}

input {
    background-color: var(--form-field-background);
    border-style: solid;
    border-width: 1px;
    border-color: var(--form-field-border-color);
    border-radius: var(--form-field-border-radius);
    font-family: var(--form-field-font-family);
    font-size: 1em;
    color: var(--form-field-font-color);
    line-height: var(--form-field-height);
    height: var(--form-field-height);

    padding: 0 0.2em 0 var(--form-field-padding);
    //padding-right: 0.2em;
    //padding-left: var(--form-field-padding);
    margin-left: 0.3em;
    margin-right: 0.3em;
    //margin-bottom: 5px;
}

input[type="checkbox"] {
    //margin-top: 2px;
    margin-top: auto;
    margin-bottom: auto;
    height: unset;
    float: left;
}

input[type="radio"] {
    //margin-top: 1px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7px;
    height: unset;
}

button {
    background-color: var(--button-background-color);
    background-image: var(--button-background-image);
    //background-image: linear-gradient(#f3f3f3, #dddddd);
    border: var(--button-border);
    border-radius: var(--form-field-border-radius);
    line-height: var(--form-field-height);
    padding: var(--button-padding);
    font-family: var(--form-field-font-family);
    font-size: 0.95em;
    font-weight: var(--button-font-weight);
    text-transform: capitalize;
    color: var(--button-font-color);
    height: var(--form-field-height);
}

button:hover {
    border-color: #666666;
}

button:active {
    border-color: #f60;
    //background-image: linear-gradient(#dddddd, #f3f3f3);
    background-color: #f0f0f0;
}

button:disabled {
    opacity: 0.7;
}

.inb-crw-img-loader-btn {
    //height: 25px;
    background-color: var(--button-background-color);
    //background-image: linear-gradient(#f3f3f3, #dddddd);
    //border-style: solid;
    //border-width: 1px;
    //border-color: #888888;
    border: var(--button-border);
    border-radius: var(--form-field-border-radius) 0 0 var(--form-field-border-radius);
    //display: table-cell;
    line-height: calc(var(--form-field-height) + 0.05em);
    height: var(--form-field-height);
    padding: 0em 0.3em 0em 0.3em;
    font-family: var(--form-field-font-family);
    font-size: 1.0em;
    text-align: center;
    color: var(--button-font-color);
}

.inb-crw-img-loader-btn:hover {
    border-color: #666666;
}

.inb-crw-img-loader-btn:active {
    border-color: #f60;
    //background-image: linear-gradient(#dddddd, #f3f3f3);
    background-color: #f0f0f0;
}

.inb-crw-img-filename {
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-color: var(--form-field-border-color);
    border-radius: 0 var(--form-field-border-radius) var(--form-field-border-radius) 0;
    width: calc(100% - 2.7em);
    //height: 1.6em;
    height: var(--form-field-height);
    line-height: var(--form-field-height);
    margin-bottom: 5px;
    padding-left: var(--form-field-padding);
    padding-right: var(--form-field-padding);
    font-family: var(--form-field-font-family);
    font-size: 1.0em;
    background-color: var(--form-field-background);
}

.inb-crw-link {
    text-decoration: underline;
    color: #f60;
}
.inb-crw-link:hover {
    cursor: pointer;
}

textarea#inb-crw-product-description {
    width: 83%;
    height: calc(2.5 * var(--form-field-height));
    border-style: solid;
    border-width: 1px;
    border-color: var(--form-field-border-color);
    border-radius: var(--form-field-border-radius);
    background-color: var(--form-field-background);
    padding: 0.2em var(--form-field-padding) 0.2em var(--form-field-padding);
    font-family: var(--form-field-font-family);
    font-size: 1em;
}

#inb-crw-map {

    margin: auto;
    width: 70%;
    height: 25em;
}

#inb-crw-button-submit {
    
    margin: auto;
    display: block;
}

.inb-crw-tooltip-container {

    position: absolute;
    font-size: 0.9em;
    color: var(--tooltip-font-color);
    max-width: 360px;

    table {
        border: none;
        border-width: 0;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0;
        padding: 0;
    }

    td {
        border: none;
        border-width: 0;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0;
        padding: 0;
    }

    .field {
        background-color: var(--tooltip-background-color);
        border-radius: 0.4em;
        border: var(--button-border);
        box-shadow: var(--form-box-shadow);
        padding: 0.5em;
        margin: 0;
    }

    .arrow {

        height: 7px;

        background-image: url('img/tooltip_tip_up.png');
        background-repeat: no-repeat;
        background-size: 12px 8px;
        background-position: 50% 0;                         // center tip horizontally
    }
}

.inb-crw-modal-overlay {

    position: absolute;
    left: 0;
    top : 0;

    background-color: #000000;
    opacity: 0.25;

    z-index: 2;
}

.inb-crw-message {

    font-family: var(--page-font-family);
    font-size: 0.9em;
    padding: 0px 12px 2px 12px;

    min-width: 250px;
    max-width: 500px;
    min-height: 80px;

    border: 1px solid var(--form-field-border-color);
    border-radius: 4px;
    box-shadow: 1px 3px 4px 1px rgba(0, 0, 0, 0.3);
    background-color: var(--content-background);

    position: absolute;
    text-align: center;

    z-index: 3;
}

.inb-crw-error {

    color: red;
}

.inb-crw-invalid {

    border: 1px solid red !important;
}

.inb-crw-form-container {

    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    max-width: 40em;
    height: 100%;
    background-color: var(--content-background);
    margin-left: auto;
    margin-right: auto;
    min-height: inherit;
}

.inb-crw-h-group2 {

    clear: both;
    margin-bottom: 5px;

    .left {
        float: left;
    }

    label {

        max-width: 20em;
        width: 50%;
    }

    label.multiline {
        line-height: 1.2em;
        padding-bottom: 5px;
    }
}

#inb-crw-product-img-container {

    margin-top: var(--form-field-margin);

    .center {
        text-align: center;
    }
}

#inb-crw-product-img-label {

    width: var(--loader-button-width);
}

.inb-crw-input-element {

    //display: table;
    display: flex;
    //height: 2.5em;
    height: var(--form-field-height);
    width: 100%;
    max-width: 15em;

    @media only screen and (min-width: 420px) {
        width: 55%;
    }

    .input-container {
        min-width: 5em;
        width: 100%;
        display: flex;
    }

    select.inb-crw-long {
        width: 100%;
    }

    select.inb-crw-short {
        width: 50%;
    }

    select#inb-crw-client-currency {
        width: 4em;
    }

    input.inb-crw-long {
        width: calc(100% - 0.5em - 2px);
    }

    input.inb-crw-short {
        width: calc(50% - 0.5em - 2px);
        min-width: 2em;
    }

    .inb-crw-h-group2 {
        width: 100%;
        margin-left: 0.3em;
        margin-right: 0.3em;
    }
}

.inb-crw-input-element.middle {
    min-width: 15em;
}

.btn-container {
    display: inline-block;
}

#inb-crw-debit-card {
    width: 100%;
    font-size: var(--page-font-size);
    color: var(--page-font-color);
    background-color: var(--form-field-background);
    border: 1px solid var(--form-field-border-color);
    border-radius: var(--form-field-border-radius);
    padding-left: var(--form-field-padding);
    margin-left: 0.3em;
    margin-right: 0.3em;
    max-width: 360px;
    padding-top: calc(0.5 * (var(--form-field-height) - var(--page-font-size)));
    padding-bottom: calc(0.5 * (var(--form-field-height) - var(--page-font-size)));
}

#inb-crw-iban-number {
    width: 100%;
    font-size: var(--page-font-size);
    color: var(--page-font-color);
    background-color: var(--form-field-background);
    border: 1px solid var(--form-field-border-color);
    border-radius: var(--form-field-border-radius);
    padding-left: var(--form-field-padding);
    margin-left: 0.3em;
    margin-right: 0.3em;
    max-width: 360px;
    padding-top: calc(0.5 * (var(--form-field-height) - var(--page-font-size)));
    padding-bottom: calc(0.5 * (var(--form-field-height) - var(--page-font-size)));
}

.inb-crw-warning {
    font-size: 1.1em;
    font-weight: 600;
    color: red;
    padding-right: 4px;
}

#inb-crw-end-page-message {
    margin-top: 30%;
}